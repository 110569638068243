// @flow
import ufc from "@tvg/api/ufc";
import { get } from "lodash";

const getUserFavoriteTracks = (accountNumber: number) =>
  ufc.getUserAlerts(accountNumber.toString(), "track").then(({ data }) => {
    const userFavorites = {};
    const ufcFavoriteTracks = data.favorites.filter(
      (favorite) => !favorite.subscriptions.length
    );
    ufcFavoriteTracks.forEach((ufcFavorite) => {
      userFavorites[ufcFavorite.entityName] = ufcFavorite.favoriteId;
    });

    return userFavorites;
  });

const getUserRaceAlerts = (accountNumber: string, entityType: string) =>
  ufc
    .getUserAlerts(accountNumber, entityType)
    .then((results) => get(results, "data.favorites", []));

export default {
  getUserFavoriteTracks,
  getUserRaceAlerts
};
