import type { SetStateAction } from "react";
import { androidSubscribeToContentCards } from "@tvg/braze/src/androidBrazeBridge";
import { get, isEqual } from "lodash";
import Poller from "@tvg/poller";
import type { Props } from "@tvg/ts-types/Login";
import type { Dispatch } from "redux";
import type { NullaryFn } from "@tvg/ts-types/Functional";
import {
  setBrazeContentCards,
  SetBrazeContentCardsAction
} from "./store/actions";
import {
  brazeChangeUser,
  brazeInitialize,
  brazeRemoveSubscription,
  brazeContentCardsRefresh,
  isBrazeInitialized,
  hasDirectIntegration
} from ".";

const BrazePoller = new Poller();

/**
 * There's a cap on the amount of refreshes we can do (3 refreshes p/ 10 minutes)
 * So we are limiting our poller to a min of 5 minutes
 * https://www.braze.com/docs/developer_guide/customization_guides/content_cards/customizing_feed/?tab=web
 */
const startContentCardsPoller = (refreshExtraPollerTimer = 0) => {
  if (!BrazePoller.isRunning()) {
    BrazePoller.start(() => {
      if (isBrazeInitialized()) {
        brazeContentCardsRefresh();
      }
    }, 300000 + refreshExtraPollerTimer);
  }
};

const initializeBraze = (
  prevProps: Props,
  nextProps: Props,
  subscription: string = "",
  setState: SetStateAction<NullaryFn<void>>,
  dispatch: Dispatch<SetBrazeContentCardsAction>,
  prevFanDuelToken?: string,
  nextFanDuelToken?: string,
  refreshExtraPollerTimer?: number
) => {
  if (typeof window !== "undefined") {
    const isFanDuelToken =
      typeof prevFanDuelToken !== "undefined" ||
      typeof nextFanDuelToken !== "undefined";

    const prevAccountId: string | undefined = isFanDuelToken
      ? prevFanDuelToken
      : get(prevProps, "user.accountNumber");

    const accountId: string | undefined = isFanDuelToken
      ? nextFanDuelToken
      : get(nextProps, "user.accountNumber");

    if (
      ((!prevProps.user.isLogged || !prevAccountId) &&
        nextProps.user.isLogged &&
        !!accountId) ||
      (prevProps.user.isLogged && !!prevAccountId && !subscription)
    ) {
      brazeInitialize(); // needed case never logged in before (!userLoginOnce)
      brazeChangeUser(nextProps.user, accountId);

      if (hasDirectIntegration() && subscription) {
        brazeRemoveSubscription(subscription);
      }
      if (hasDirectIntegration()) {
        if (window.braze) {
          const subscriptionID = window.braze.subscribeToContentCardsUpdates(
            (cards) => {
              dispatch(
                setBrazeContentCards({
                  unviewed: cards.getUnviewedCardCount(),
                  totalCards: get(cards, "cards.length", 0),
                  cards: get(cards, "cards", 0)
                })
              );
            }
          );
          // This needs to be called after the subscribeToContentCardsUpdates
          window.braze.openSession();
          startContentCardsPoller(refreshExtraPollerTimer);

          setState(() => ({ subscription: subscriptionID }));
        }
      } else {
        androidSubscribeToContentCards();
        setState(() => ({ subscription: "subscription" }));
      }
    } else if (
      window.localStorage.getItem("userLoginOnce") &&
      !isBrazeInitialized()
    ) {
      brazeInitialize();
    }

    if (
      !isEqual(prevProps.user.isLogged, nextProps.user.isLogged) &&
      !isEqual(accountId, prevAccountId) &&
      BrazePoller.isRunning()
    ) {
      BrazePoller.stop();
    }
  }
};

export default initializeBraze;
